/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Outlet } from 'react-router'

import { styled } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import {
  Badge,
  Collapse,
  colors,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Drawer,
  Box,
  CircularProgress,
  CircularProgressProps,
  Backdrop,
} from '@mui/material'
import {
  ExpandMore,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Menu,
  Mail,
  Logout,
} from '@mui/icons-material'

import { useUserData } from 'hooks/useUserData'
import { useMenu } from 'hooks/useMenu'
import { Course, useCourses } from 'hooks/useCourses'
import { Student, useStudents } from 'hooks/useStudents'
import { Bill, useBills } from 'hooks/useBills'
import { useConfigs } from 'hooks/useConfigs'
import { Teacher, useTeachers } from 'hooks/useTeachers'
import { billsDescriptions } from 'mocks/bill'
import { database, firebase } from 'config/firebase'
import { menu } from 'enum/menus'
import { MaskMoney } from 'utils/mask'
import { checkDateIsAfter, convertTimestamp, sortByLargestDate } from 'utils/functions'
import { useMessages } from 'hooks/useMessages'

const drawerWidth: number = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const MuiDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(0)
        }
      })
    }
  })
)

let countdownTimeout: NodeJS.Timeout;

const AuthLayout = () => {
  const navigate = useNavigate()
  const { name } = useMenu()
  const { role, name: nameOfUser } = useUserData()

  const [messages, setMessages] = useState(0)
  const [open, setOpen] = useState(true)
  const [title, setTitle] = useState(name)
  const [openMenu, setOpenMenu] = useState<any>(null)
  const [currentMenu, setCurrentMenu] = useState<any[]>([])
  const [progress, setProgress] = useState(0);

  const toggleDrawer = () => {
    setOpen(!open)
  }

  function changeMenu(menu: any) {
    useMenu.setState({ ...menu })
    setTitle(menu.name)
    navigate(menu.path)
  }

  async function logout() {
    await firebase.auth().signOut()
    navigate('/')
    useUserData.setState({
      logged: false,
      id: '',
      email: '',
      name: '',
      role: '',
      accessToken: ''
    })
    localStorage.clear()
    toast.success('Log-out successful!')
  }

  async function getCourses() {
    await database
      .collection('courses')
      .onSnapshot(query => {
        const data = query.docs.map((doc) => {
          return {
            firebaseId: doc.id,
            ...doc.data()
          }
        }) as Course[]

        useCourses.setState(data)
      })
  }

  async function getStudents() {
    await database
      .collection('students')
      .onSnapshot(query => {
        const data = query.docs.map((doc) => {
          return {
            ...doc.data(),
            firebaseId: doc.id,
            amountMask: MaskMoney(doc.data().amount),
            outdatedContract: checkDateIsAfter(convertTimestamp(doc.data().createdAt), 365)
          }
        }) as Student[]

        useStudents.setState(data)
      })
  }

  async function getTeachers() {
    await database
      .collection('teachers')
      .onSnapshot(query => {
        const data = query.docs.map((doc) => {
          return {
            ...doc.data(),
            firebaseId: doc.id,
            amountMask: MaskMoney(doc.data().amount)
          }
        }) as Teacher[]

        useTeachers.setState(data)
      })
  }

  async function getBills() {
    await database
      .collection('bills')
      .onSnapshot(query => {
        const data = query.docs.map((doc) => {
          const { name } = billsDescriptions.filter(b => b.id === doc.data().description)[0]

          return {
            ...doc.data(),
            firebaseId: doc.id,
            date: convertTimestamp(doc.data().date),
            name
          }
        }) as Bill[]

        useBills.setState(sortByLargestDate(data))
      })
  }

  async function getConfigs() {
    await database
      .collection('configurations')
      .onSnapshot(query => {
        const [data] = query.docs.map((doc) => {
          return {
            ...doc.data(),
            firebaseId: doc.id,
          }
        }) as any

        useConfigs.setState(data)
      })
  }

  async function getMessages() {
    await database
      .collection('contact')
      .onSnapshot(query => {
        const data = query.docs.map((doc) => {
          return {
            ...doc.data(),
            firebaseId: doc.id,
          }
        }) as any

        useMessages.setState(data)
        setMessages(data.filter((m: any) => !m.read).length)
      })
  }

  function changeMenuOpen(menu: any) {
    if (openMenu) {
      if (menu === openMenu) {
        return setOpenMenu(null)
      }

      setOpenMenu(menu)
    } else setOpenMenu(menu)
  }

  useEffect(() => {
    if (role === 'ADMIN') {
      getCourses()
      getStudents()
      getTeachers()
      getBills()
    }

    getConfigs()
    getMessages()

    const m = menu.filter(m => m.roles.includes(role as string))
    setOpenMenu(m[0])
    navigate(m[0].children[0].path)
    setTitle(m[0].children[0].name)
    setCurrentMenu(m)
  }, [])

  useEffect(() => {
    if (progress >= 0) {
      countdownTimeout = setTimeout(() => {
        setProgress(progress + 1)
      }, 35);
    } else if (progress === 100) {
      clearTimeout(countdownTimeout)
    }

  }, [progress]);

  useEffect(() => {
    const [m] = menu.filter(m => m.roles.includes(role as string))
    navigate(m.children[0].path)
    setTitle(m.name)
    setOpen(window.innerWidth > 480)
  }, [role])

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}>

        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" size={64} {...props} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="body1"
              component="div"
              color={colors.common.white}
            >
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>

      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>

      <AppBar color="primary" position="absolute" open={open}>
        <Toolbar sx={{ pr: '24px' }} >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' })
            }}
          >
            <Menu />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="white"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>
          <IconButton size="large" aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={messages} color="error">
              <Mail />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={logout}>
            <Logout />
          </IconButton>
        </Toolbar>
      </AppBar>
      <MuiDrawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: [1]
          }}
        >
          <Box sx={{ ml: 3 }} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography fontWeight={700} variant="h6">{nameOfUser?.split(' ')[0]}</Typography>
            <Typography variant="caption">{role}</Typography>
          </Box>
          <IconButton onClick={toggleDrawer}>
            <KeyboardArrowLeft />
          </IconButton>
        </Toolbar>
        <Divider />
        <nav>
          <List>
            {
              currentMenu.map((menu, index) => (
                <Box key={index}>
                  <ListItem sx={{ background: colors.grey.A100, mt: 2 }}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: '100%', p: 1, cursor: 'pointer' }}
                      onClick={() => changeMenuOpen(menu)}>
                      <Typography fontWeight={700} variant="subtitle1">{menu.name}</Typography>
                      {openMenu === menu ? <ExpandMore /> : < KeyboardArrowRight />}
                    </Box>
                  </ListItem>
                  <Collapse in={openMenu === menu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding sx={{ ml: 4 }}>
                      {
                        menu.children.map((m: any, i: any) => (
                          <ListItemButton sx={{ borderBottomLeftRadius: m.name === title ? 8 : 0, borderTopLeftRadius: m.name === title ? 8 : 0, bgcolor: m.name === title ? colors.teal[50] : 'transparent' }} onClick={() => changeMenu(m)}>
                            <ListItemText primary={m.name} />
                          </ListItemButton>
                        ))
                      }
                    </List>
                  </Collapse>
                </Box>
              ))
            }
          </List>
        </nav>
      </MuiDrawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => theme.palette.grey[100],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto'
        }}
      >
        <Toolbar />
        {
          progress <= 100
            ? (
              <Backdrop
                sx={{ color: colors.common.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={progress <= 100}
              >
                <CircularProgressWithLabel color="inherit" value={progress} />
              </Backdrop>
            ) : (
              <Box sx={{ m: 4 }}>
                <Outlet />
              </Box>
            )
        }
      </Box>
    </Box>
  )
}

export default AuthLayout
