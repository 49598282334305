
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'

import { styleModal } from 'components/Modal/style'
import { database } from 'config/firebase'
import { useCourses } from 'hooks/useCourses'
import { toast } from 'react-toastify'
import { isMobile } from 'utils/functions'

const Testimonies = () => {
  const [openModalNewStudent, setOpenModalNewTestimonie] = useState(false)
  const [name, setName] = useState('')
  const [courses, setCourses] = useState<string[]>([]);
  const [testimonie, setTestimonie] = useState('');
  const [testimonies, setTestimonies] = useState<any[]>([]);
  const [id, setId] = useState('')

  const useCoursesState = useCourses()
  const AllCourses = Object.values(useCoursesState)

  const columns = [
    {
      id: 1,
      label: 'Nome Completo',
      value: 'name'
    },
    {
      id: 2,
      label: 'Cursos',
      value: 'course'
    },
    {
      id: 3,
      label: 'Ações',
      value: 'action'
    }
  ]

  async function handleAddTestimonies() {

    if (id) {
      await database
        .collection('testimonies')
        .doc(id)
        .update({
          name,
          courses,
          testimonie
        })
      toast.success('Depoimento editado com sucesso')
      return closeModal()
    }

    await database
      .collection('testimonies')
      .add({
        name,
        courses,
        testimonie
      })
    toast.success('Depoimento criado com sucesso')
    closeModal()
  }

  const handleChangeCourse = (event: any) => {
    const {
      target: { value },
    } = event;

    setCourses(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  async function getTestimonies() {
    await database
      .collection('testimonies')
      .onSnapshot(query => {
        const data = query.docs.map((doc) => {
          return {
            ...doc.data(),
            firebaseId: doc.id,
          }
        }) as any[]

        setTestimonies(data)
      })
  }

  function closeModal() {
    setOpenModalNewTestimonie(false)
    setName('')
    setCourses([])
    setTestimonie('')
    setId('')
  }

  function editTestimonies({ name, courses, testimonie, firebaseId }: any) {
    setOpenModalNewTestimonie(true)
    setName(name)
    setCourses(courses)
    setTestimonie(testimonie)
    setId(firebaseId)
  }

  async function deleteTestimonies(firebaseId: string) {
    if (window.confirm('Você tem certeza que deseja excluir esse depoimento ?')) {
      await database
        .collection('testimonies')
        .doc(firebaseId)
        .delete()
      toast.success('Depoimento excluido com sucesso!')
    }
  }

  useEffect(() => {
    getTestimonies()
  }, [])

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Todos os depoimentos
        </Typography>
        <Button
          onClick={() => setOpenModalNewTestimonie(true)}
          variant="contained"
        >
          Cadastrar novo depoimento
        </Button>
      </div>

      {/* Modal Add testmonies */}
      <Modal
        open={openModalNewStudent}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: isMobile() ? '80vw' : '50vw', }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={3}>
            Cadastre um novo depoimento
          </Typography>

          <Grid container spacing={1} >
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nome do aluno"
                value={name}
                onChange={text => setName(text.target.value)}
                variant="filled"
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Cursos</InputLabel>
                <Select
                  multiple
                  value={courses}
                  onChange={handleChangeCourse}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {AllCourses.map(({ name, id }) => (
                    <MenuItem key={id} value={name}>
                      <Checkbox checked={courses.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Depoimento"
                multiline
                rows={4}
                value={testimonie}
                onChange={text => setTestimonie(text.target.value)}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={handleAddTestimonies}
                variant="contained"
                sx={{ mt: 2 }}
                fullWidth
              >
                Cadastrar Depoimentos
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {
        testimonies.length === 0 ? (
          <Typography sx={{ mt: 4 }} variant="h5" component="h5">
            Não temos depoimentos cadastrados
          </Typography>
        ) : (
          <Paper sx={{ borderRadius: 2, p: 2, display: 'flex', flexDirection: 'column', mt: 3 }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  {columns.map((column) => <TableCell key={column.id}>{column.label}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {testimonies.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.courses.join(', ')}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        color="info"
                        onClick={() => editTestimonies(row)}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        sx={{ ml: 1 }}
                        onClick={() => deleteTestimonies(row.firebaseId)}
                      >
                        Excluir
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )
      }

    </>
  )
}

export default Testimonies
