import create from 'zustand'

export type Teacher = {
  amount: number;
  amountMask: string;
  courses: string[];
  firebaseId: string;
  id: string;
  name: string;
  phone: string;
  bank: string;
  status: string;
  code: string;
  isFirstAccess: boolean
}

export const useTeachers = create<Teacher[]>(() => ([]))
