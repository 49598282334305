import create from 'zustand'

export interface Message {
  firebaseId: string;
  name: string;
  email: string;
  phone: string;
  message: string;
  read: boolean
}

export const useMessages = create<Message[]>(() => ([]))
