
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  colors,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { v4 as uuidV4 } from 'uuid'

import { styleModal } from 'components/Modal/style'
import { database } from 'config/firebase'
import { Student, useStudents } from 'hooks/useStudents'
import { useCourses } from 'hooks/useCourses'
import { Teacher, useTeachers } from 'hooks/useTeachers'
import { MaskMoney } from 'utils/mask'

import { Timestamp } from "firebase/firestore";
import { isMobile } from 'utils/functions'
import { toast } from 'react-toastify'

const Students = () => {
  const [openModalNewStudent, setOpenModalNewStudent] = useState(false)
  const [name, setName] = useState('')
  const [course, setCourse] = useState('')
  const [amount, setAmount] = useState('')
  const [teacher, setTeacher] = useState<Teacher | null>(null)
  const [email, setEmail] = useState('')
  const [registration, setRegistration] = useState(190)

  const [studentsOutdatedContract, setStudentsOutdatedContract] = useState(0)
  const [modalStudentsOutdatedContract, setModalStudentsOutdatedContract] = useState(false)

  const usStudentsContext = useStudents()
  const useCoursesState = useCourses()
  const useTeactherState = useTeachers()

  const courses = Object.values(useCoursesState)
  const students = Object.values(usStudentsContext)
  const teachers = Object.values(useTeactherState)

  const columns = [
    {
      id: 1,
      label: 'Nome Completo',
      value: 'name'
    },
    {
      id: 2,
      label: 'Curso',
      value: 'course'
    },
    {
      id: 3,
      label: 'Valor total da aula',
      value: 'amountMask'
    },
    {
      id: 4,
      label: 'Status',
      value: 'status'
    },
    {
      id: 5,
      label: 'Ações',
      value: 'action'
    }
  ]

  async function handleAddStudent() {

    await database
      .collection('students')
      .add({
        name,
        course,
        amount: teacher && teacher.amount + +amount,
        revenue: +amount,
        id: uuidV4(),
        teacher: teacher?.id,
        status: 'active',
        email,
        registration,
        createdAt: Timestamp.fromDate(new Date()),
      })

    closeModal()
  }

  function closeModal() {
    setOpenModalNewStudent(false)
    setName('')
    setCourse('')
    setAmount('')
    setTeacher(null)
    setEmail('')
    setRegistration(190)
  }

  // async function handleChaneStatus(id: string, status: string) {
  //   if (window.confirm('Você tem certeza que deseja excluir esse aluno ?')) {
  //     await database
  //       .collection('students')
  //       .doc(id)
  //       .update({ status })
  //   }
  // }

  const Rows = students.map(s => {
    const [studentCourse] = courses.filter(item => item.id === s.course)

    return {
      ...s,
      nameOfCourse: studentCourse.name,
      status: s.status === 'active' ? 'Ativo' : 'Inativo',
      statusColor: s.status === 'active' ? colors.green[500] : colors.red[500],
    }
  })

  // const handleNavigateToTeacherPage = () => {
  //   history('/teachers')
  //   useMenu.setState({
  //     name: 'Professores',
  //     path: '/teachers',
  //   })
  // }

  const handleRegisterNewStudent = () => {
    if (courses.length === 0 && teachers.length === 0) {
      toast.error('Para realizar o cadastro de um novo aluno é obrigatório ter pelo menos um curso e um professor cadastrado')
      return
    }

    if (courses.length === 0) {
      toast.error('Para realizar o cadastro de um novo aluno é obrigatório ter pelo menos um curso cadastrado')
      return
    }

    if (teachers.length === 0) {
      toast.error('Para realizar o cadastro de um novo aluno é obrigatório ter pelo menos um professor cadastrado')
      return
    }

    setOpenModalNewStudent(true)
  }


  useEffect(() => {
    const outdatedContract = students.filter(student => student.outdatedContract)

    if (outdatedContract.length > 0) {
      setStudentsOutdatedContract(outdatedContract.length)
      setModalStudentsOutdatedContract(true)
    }
  }, [])

  const handleOutdatedContract = (row: Student) => {
    alert('Função em desenvolvimento 👨🏻‍💻')
    // REGULARIZAR CONTRATO
    // Essa função será responsavel por regularizar o contrato do aluno.

    // REGRA ATUAL: O contrato do aluno fica irregular se a data de criação do ALUNO for maior que 365 dias.

    // ***** RESOLUÇÃO *****

    // ***** NOVA VERIFICAÇÃO *****
    // - Criar uma tabela (contract) onde serão armazenados os contratos e a chave para vincular o aluno é o ID
    // - Criar um campo contract na tabela do aluno e verficar a data com a data atual, se for maior que 365 dias estará irregular

    // ***** FUNCIONALDIADE *****
    // - Abrir um modal para o upload de um novo contrato
    // - Fazer o upload para o storage (symphonia/contracts/firebaseID ou uuid do aluno)
    // - Adicionar um novo registro na tabela contract
    // - Atualizar a coluna contract na tabela de students
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Todos os alunos
        </Typography>
        <Button
          onClick={handleRegisterNewStudent}
          variant="contained"
        >
          Cadastrar novo aluno
        </Button>
      </div>

      <Modal
        open={modalStudentsOutdatedContract}
        onClose={() => setModalStudentsOutdatedContract(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: '30vw', border: '10px solid #CA9F6A' }}>
          <Typography color="secondary" fontWeight={700} id="modal-modal-title" variant="h5" component="h1" mb={3}>
            Atenção!!
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h5" mb={3}>
            Ao iniciar o sistema, notamos que temos <b style={{ color: '#f44336' }}>{studentsOutdatedContract} aluno(s) com o contrato defasado</b>, por gentileza atualize o contrato.
          </Typography>
        </Box>
      </Modal>

      {/* Modal Add student */}
      <Modal
        open={openModalNewStudent}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...styleModal, width: isMobile() ? '80vw' : '50vw', }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" mb={3}>
            Cadastre um novo aluno
          </Typography>

          <Grid container spacing={1} >
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nome do aluno"
                value={name}
                onChange={text => setName(text.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="E-mail para contato"
                value={email}
                onChange={text => setEmail(text.target.value)}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Curso</InputLabel>
                <Select
                  value={course}
                  onChange={text => setCourse(text.target.value)}
                >
                  {
                    courses.map((course: any) => <MenuItem key={course.id} value={course.id}>{course.name}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Professor</InputLabel>
                <Select
                  value={teacher}
                  onChange={(text: any) => setTeacher(text.target.value)}
                  disabled={!course}
                >
                  {
                    teachers
                      .filter(currentTeacher => currentTeacher.status === 'active')
                      .filter(currentTeacher => currentTeacher.courses.includes(course))
                      .map((currentTeacher: any) =>
                        <MenuItem
                          key={currentTeacher.id}
                          value={currentTeacher}
                        >
                          {currentTeacher.name}
                        </MenuItem>
                      )
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Valor do professor/custo"
                value={teacher?.amountMask}
                defaultValue="R$ 0.00"
                variant="filled"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Valor do aluno/lucro"
                value={amount}
                onChange={text => setAmount(text.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Valor total da aula"
                value={teacher && MaskMoney(teacher.amount + +amount)}
                defaultValue="R$ 0.00"
                variant="filled"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Valor matrícula"
                value={MaskMoney(registration)}
                onChange={text => setRegistration(+text.target.value)}
                variant="filled"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={handleAddStudent}
                variant="contained"
                sx={{ mt: 2 }}
                fullWidth
              >
                Cadastrar aluno
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {
        students.length === 0 ? (
          <Typography sx={{ mt: 4 }} variant="h5" component="h5">
            Não temos alunos cadastrados
          </Typography>
        ) : (
          <Paper sx={{ borderRadius: 2, p: 2, display: 'flex', flexDirection: 'column', mt: 3 }}>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) =>
                    <TableCell
                      align={index === 0 ? 'inherit' : 'right'}
                      key={column.id}
                    >
                      {column.label}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Rows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell align="right">{row.nameOfCourse}</TableCell>
                    <TableCell align="right">{row.amountMask}</TableCell>
                    <TableCell align="right" sx={{ color: row.statusColor }}>{row.status}</TableCell>
                    <TableCell align="right">
                      {
                        row.outdatedContract && (
                          <Button
                            variant="contained"
                            size="small"
                            color="error"
                            onClick={() => handleOutdatedContract(row)}
                            sx={{ mx: 1 }}
                          >
                            Regularizar contrato
                          </Button>
                        )
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )
      }

    </>
  )
}

export default Students
