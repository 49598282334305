import React, { useEffect, useState } from 'react'
import { colors, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material'

import { useCourses } from 'hooks/useCourses';
import { useStudents } from 'hooks/useStudents';
import { useTeachers } from 'hooks/useTeachers';

import { MaskMoney } from 'utils/mask';
import { BarChart } from 'components/Charts';
import { months } from 'mocks/dates';

const Dashboard = () => {
  const [loader, setLoader] = useState(true)
  const [month, setMonth] = useState(months[2])
  const [year, setYear] = useState('2022')

  const useTeachersState = useTeachers()
  const useStudentsState = useStudents()
  const useCoursesState = useCourses()

  const courses = Object.values(useCoursesState)
  const students = Object.values(useStudentsState)
  const teachers = Object.values(useTeachersState)

  const totalRenenue = students
    .filter(s => s.status === 'active')
    .reduce((acc, current) => acc + current.revenue, 0)

  const total = students
    .filter(s => s.status === 'active')
    .reduce((acc, current) => acc + current.amount, 0)

  const totalWithdraw = total - totalRenenue

  const totalStudentsPerCourse = courses.map(course => {
    const allStudents = students
      .filter(s => s.status === 'active' && s.course === course.id)
    return {
      name: course.name,
      value: allStudents.length
    }
  })

  const championCourse = totalStudentsPerCourse.reduce((acc, crr) => acc.value > crr.value ? acc : crr, { name: '', value: 0 })

  const allCourses = totalStudentsPerCourse.filter(c => c.value > 0)

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 1500);
  }, [])

  if (loader) {
    return <h1>Montando o dashboard, aguarde...</h1>
  }

  return (

    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Dashboard professores
        </Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControl variant="filled" fullWidth size="small">
          <InputLabel>Ano</InputLabel>
          <Select
            value={year}
            onChange={(text: any) => setYear(text.target.value)}
          >
            {
              ['2021', '2022'].map((currentYear: any) =>
                <MenuItem
                  key={currentYear}
                  value={currentYear}
                >
                  {currentYear}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControl variant="filled" fullWidth size="small">
          <InputLabel>Meses</InputLabel>
          <Select
            value={month}
            onChange={(text: any) => setMonth(text.target.value)}
          >
            {
              months.map((currentMonth: any) =>
                <MenuItem key={currentMonth} value={currentMonth}>
                  {currentMonth}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} />

      <Grid item xs={12} md={3}>
        <Paper
          sx={{
            borderRadius: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Total de cursos <b>ativos</b>
            </Typography>
            <Typography component="p" variant="h4">
              {allCourses.length}
            </Typography>
          </>
          {
            courses.length > 0 && (
              <>
                <br />
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                  {`Curso campeão com ${championCourse.value} aluno(s)`}
                </Typography>

                <Typography component="p" variant="h4">
                  {championCourse.name}
                </Typography>
              </>
            )
          }

        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
          sx={{
            borderRadius: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Total de <b>alunos ativos</b>
            </Typography>
            <Typography component="p" variant="h4">
              {
                students
                  .filter(student => student.status === 'active')
                  .length
              }
            </Typography>
          </>
          <br />
          <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Total de <b>alunos inativos</b>
            </Typography>
            <Typography component="p" variant="h4">
              {
                students
                  .filter(student => student.status !== 'active')
                  .length
              }
            </Typography>
          </>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
          sx={{
            borderRadius: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Total de <b>professores ativos</b>
            </Typography>
            <Typography component="p" variant="h4">
              {
                teachers
                  .filter(teacher => teacher.status === 'active')
                  .length
              }
            </Typography>
          </>
          <br />
          <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Total de <b>professores inativos</b>
            </Typography>
            <Typography component="p" variant="h4">
              {
                teachers
                  .filter(teacher => teacher.status !== 'active')
                  .length
              }
            </Typography>
          </>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
          sx={{
            borderRadius: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Total de <b>lucro</b>
            </Typography>
            <Typography component="p" variant="h4" sx={{ color: totalRenenue > totalWithdraw ? colors.green[500] : colors.red[500] }}>
              {MaskMoney(totalRenenue)}
            </Typography>
          </>
          <br />
          <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
              Total de <b>saída (professores)</b>
            </Typography>
            <Typography component="p" variant="h4" sx={{ color: colors.red[500] }}>
              {MaskMoney(totalWithdraw)}
            </Typography>
          </>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <BarChart
          title="Resumo geral dos alunos"
          labels={['Alunos Ativos', 'Alunos Inativos']}
          datasets={[
            students.filter(student => student.status === 'active').length,
            students.filter(student => student.status !== 'active').length
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BarChart
          title="Resumo geral dos Professores"
          labels={['Ativos', 'Inativos']}
          datasets={[
            teachers.filter(teacher => teacher.status === 'active').length,
            teachers.filter(teacher => teacher.status !== 'active').length,
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BarChart
          title="Resumo geral dos aluno por curso"
          labels={totalStudentsPerCourse.map(course => course.name)}
          datasets={totalStudentsPerCourse.map(course => course.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BarChart
          title="Toal de gastos"
          labels={['Total geral', 'Total de despesa', 'Total de receita']}
          datasets={[
            total,
            totalWithdraw,
            totalRenenue
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default Dashboard
