export const convertTimestamp = (timestamp: any): Date => {
  const date = timestamp.toDate();
  const mm = date.getMonth();
  const dd = date.getDate();
  const yyyy = date.getFullYear();
  const HH = date.getHours()
  const m = date.getMinutes()
  const ss = date.getSeconds()
  return new Date(yyyy, mm, dd, HH, m, ss)
}

export const sortByLargestDate = (arr: any[]) => {
  return arr.sort((a, b) => b.date.getTime() - a.date.getTime())
}

export const isMobile = () => window.screen.width <= 375

export const checkDateIsAfter = (createdAt: Date, day: number) => {
  const now = new Date()
  const past = createdAt
  const diff = Math.abs(now.getTime() - past.getTime())
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24))

  return day < days
}